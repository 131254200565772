import React, { useEffect }  from 'react';
import WOW from "wowjs";
import { Link } from 'react-router-dom';
import services from '../components/services';
import RenderService from '../components/RenderService';

function ServiceOne(){
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  
    return(
      <div>
          <div>
            <div className="tmp-services-area services-style--1 tmp-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head">
                      
                      <h2 className="title split-collab">
                        Our Full Suite of Services
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row g-5">
                  {services.map(RenderService)}
                </div>
                {/* <div className="row">
                  <div className="col-lg-12">
                    <div className="services-bottom">
                      <div className="services-bottom-inner">
                        <p className="description">
                          Our success as consultants will depend on the essential rightness of the advice.
                        </p>
                        <p className="description">
                          Want to learn more About us?
                          <button className="btn-simple">
                            Click here
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
      </div>
    )
}


export default ServiceOne





