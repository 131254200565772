
import './App.css';
import Home from './home/Home';
import About from './inner/About';
import Service from './inner/Service';
import TeamDetails from './inner/TeamDetails';
import Error from './inner/Error';
import Contact from './inner/Contact';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import LoadTop from './components/LoadTop';
import BackTop from './components/BackTop';
import CurrentLink from './components/CurrentLink';
import Preloader from './components/Preloader';

function App() {

  
  return (
    <div className="App">

      <Router>

        <LoadTop />
        <CurrentLink />
        <BackTop />
        
       

        <Routes>
          <Route path="/" element={<Home/>}> </Route>
          <Route path="/about" element={<About/>}> </Route>
          <Route path="/service" element={<Service/>}> </Route>
    
          {/* <Route path="/TeamOne" element={<TeamOne/>}> </Route>
          <Route path="/TeamTwo" element={<TeamTwo/>}> </Route> */}
          <Route path="/teamDetails" element={<TeamDetails/>}> </Route>
          {/* <Route path="/Pricing" element={<Pricing/>}> </Route>
          <Route path="/FaqOne" element={<FaqOne/>}> </Route> */}
          <Route path="/Error" element={<Error/>}> </Route>
          <Route path="/contact" element={<Contact/>}> </Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;


