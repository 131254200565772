const partners = [
  {
    id: "1",
    img: "hand_phone.jpg",
    firstName: "David",
    lastName: "Ross",
    qualification: [
      "Executive MBA, Strategic Management Executive, AGSM, UNSW Business School",
      "Advanced Conflict Transformation, Galtung-Institute for Peace Theory and Peace Practice",
      "Graduate Certificate of Futures Studies, University of the Sunshine Coast",
      "Masters in Environmental Engineering and Management, University of Technology Sydney",
      "Bachelor of Science (Hons), Environmental Biology, University of Technology Sydney",
    ],
    title: "Strategist, Peacemaker & C-Suite Coach",
    linkedinUrl: "https://www.linkedin.com/in/david-ross-vuca-strategist/",
    description:
      "David is a true Executive Coach and master of strategically leading organisations and programs in an age of uncertainty, complexity and conflict. <br/>Renowned for his mastery of dealing with complex and often polarising issues, David helps build capabilities within leaders, organisations, and communities to be able to thrive despite the high stakes associated with ever-increasing bumps, shocks, and crises.<br/>He draws on his distinctive background in leadership, strategic management and transformational foresight, sustainability, stakeholder engagement and the resolution of conflict and scandals, to deliver bespoke solutions and long-lasting relationships with all who cross his path. <br/>You can buy his multi-award winning book on cultivating leadership skills 'Confronting the Storm' <a href=\"https://www.amazon.com/Confronting-Storm-Regenerating-Leadership-Uncertainty/dp/1637422962\">here.</a>",
  },
  {
    id: "7",
    img: "hand_phone.jpg",
    firstName: "Joseph",
    lastName: "Smith",
    linkedinUrl: "https://www.linkedin.com/in/jfsmth/",
    qualification: [
      "Masters of Business Administration (MBA) Australian Institute of Business",
      "Diploma of Business (Dip Bus) Australian Institute of Management",
    ],
    title: "Strategic Alliances Advisor",
    description:
      "Joseph has worked in the technology field for three decades advising on technology and business transformation projects for thousands of clients. He has spent the past decade building strategic alliances and growing businesses that produce revenues in the hundreds of millions of dollars, working with industry leaders such as HP, Singtel, Deloitte and Amazon Web Services.<br/> As for his superpower, Joseph is famous for quickly building trust which gives him the ability to create and execute large scale change initiatives that drive impact. Joseph is analytical and uses data-driven decision making with a focus on over-achieving metrics and goals. He is great at identifying organizational blockages and creates mechanisms to permanently resolve hinderances to growth. ",
  },
  {
    id: "2",
    img: "hand_phone.jpg",
    firstName: "Rabia",
    lastName: "Bouchiba",
    linkedinUrl: "https://www.linkedin.com/in/rabia-b-312a397/",
    qualification: [
      "Bachelor of Arts (Social Work) Leiden, The Netherlands",
      "Graduate Diploma (Media) Media Academy, Amsterdam",
      "Bachelor of Arts (Film Studies) National Film and Television School, Amsterdam",
      "Master of Arts (Creative Scriptwriting) Binger Institute Amsterdam",
    ],
    title: "Content Producer",
    description:
      "Rabia is a Director/Producer and Writer who has been delighting audiences with dramas, documentaries, music videos, and corporate films in the Netherlands, UK, and the Middle East for the past thirty years. Fluent in Arabic, English and Dutch, our resident polyglot graduated from the National Television and Film school, Amsterdam in 1994, and completed her Post grad in creative writing at “The Maurits Binger Institute” in 1998.",
  },
  {
    id: "3",
    img: "hand_phone.jpg",
    firstName: "Tom",
    lastName: "Swanston",
    linkedinUrl: "https://www.linkedin.com/in/tomswanston/",
    qualification: [
      "Bachelor of Arts Anthropology (Honours), Durham University",
    ],
    title: "C-Suite Mentor",
    description: `Tom empowers clients to grow and flourish in their professional and personal lives. He helps people to streamline their success by taking them through deep self-discovery guided by experienced coaching and story-telling techniques.<br/>He is valued for helping clients achieve clarity of vision, which saves them significant time and resources.<br/>Producing content for brands such as Netflix, Amazon, MTV, IBM and Microsoft, Tom has had an illustrious career as a filmmaker, winning awards in New York and Cannes, as well as producing films with A-list talent such as Arnold Schwarzenegger and Kelsey Grammer.<br/>Tom is an assertive listener who can synthesise information, giving clients the focus they need to fulfil their mission. Whether this is expressed through strategic planning sessions to drive revenue growth, support on navigating complex deal negotiations, or personal growth development strategies, Tom is the guy that every CEO needs to bolster their success.`,
  },
  {
    id: "4",
    img: "hand_phone.jpg",
    firstName: "Joanne Leila",
    lastName: "Smith",
    linkedinUrl: "https://www.linkedin.com/in/joanne-leila-gill-8167aa2b0/",
    qualification: [
      "Master of Law, University of Law, London UK",
      "Graduate Diploma of Education (English), University of New South Wales, Australia",
      "Master of Arts (English), University of New South Wales, Australia",
      "Bachelor of Arts (Media & Communications), Southern Cross University, Australia",
      "Diploma of Journalism, Macleay College Sydney, Australia",
    ],
    title: "Co-Founder / BD Director",
    description:
      "Jo is a highly accomplished Professional Editor and Journalist and has nearly thirty years of experience working in Business and Market Development across professional services’ industries. She is a proud Aussie Muslim, wife and mom, a published author and poet, as well as an editor of numerous works for private clients.",
  },
  {
    id: "5",
    img: "hand_phone.jpg",
    firstName: "Husni",
    lastName: "Mohammed",
    linkedinUrl: "https://www.linkedin.com/in/husni-husen-089598188/",
    qualification: [
      "Master of Science (Applied AI & Computer Science), University of Gothenburg & Lulea Uni of Technology, Sweden",
      "Master of Science (Production Engineering & Management), Chalmers University of Technology, Sweden",
      "Bachelor of Science (Mechanical & Industrial Engineering), Addis Ababa University, Ethiopia",
    ],
    title: "Co-Founder / CTO",
    description:
      "Based in Sweden, Ethiopian-born Husni is a Full Stack Developer whiz in JavaScript, Node.js, and TypeScript, with extensive experience in cloud services, system deployment, and Agile methodologies. Proficient in cloud platforms like Google Cloud and AWS, and skilled in Solidity, Firebase, and relational databases like PostgreSQL.",
  },
  {
    id: "6",
    img: "hand_phone.jpg",
    firstName: "Ioana",

    lastName: "Dumea",
    linkedinUrl: "https://www.linkedin.com/in/ioanadumea/",
    title: "Business & ESG Policy Advisor",
    description: `Ioana has advised on technology and business transformation projects for clients such as BCG, EY and NTT Data.<br/> Her superpower is in helping companies navigate the challenges of innovation, growth, and sustainable scalability. <br/>Fluent in English and Romanian, Ioana empowers MNCs and startups to develop clear, actionable strategies that drive growth, operational excellence, and a sustainable competitive edge. <br/>Leveraging her background in Public Policy, Ioana (GRI-Certified) also leads the way in helping organisations implement ESG principles into their operations.`,
    qualification: [
      "Executive Degree, International Management in Asia Pacific , INSEAD ",
      "Master's degree, Public Policy and Administration, Northwestern University",
      "Bachelor of Arts International Affairs, Economics, Spanish, Marquette University",
      "Master's degree, Public Administration, Northwestern University",
    ],
  },
  {
    id: "8",
    img: "hand_phone.jpg",
    firstName: "Archana Khatri",

    lastName: "Das",
    linkedinUrl: "https://www.linkedin.com/in/archana-khatri-das-6193a26",
    title: "Content Producer",
    description: `Archana is a versatile content creator and strategist with a rich portfolio spanning over 13 years in journalism, content creation, and digital PR. Creating stories for the news-savvy, global trend trackers to business blogs, ghostwriting for C-suite executives, or strategy-driven digital marketing assets, Archana takes the time getting to know her audience well before creating compelling narratives. Whether it be for audiences in Asia to Europe, Archana merges data and talent to produce emotive multi-modal content in English, Bengali and Hindi.<br/>
Archana is also an educator and volunteers in youth mentor program that serves underprivileged communities in rural areas in North India, teaching digital literacy and communication skills to support their self-development and employability. `,
    qualification: [
      "Bachelor's Degree in English, History and Economics",
      "Bachelor’s Degree in Journalism and Mass Communications",
      "HubSpot certifications in Digital Marketing.",
    ],
  },
  {
    id: "9",
    img: "hand_phone.jpg",
    firstName: "Hadeel",

    lastName: "Al-Qatamin",
    linkedinUrl: "https://www.linkedin.com/in/hadeel-qatamin/",
    title: "Researcher/Writer & Educator",
    description: `Hadeel is dedicated to advancing society through impactful research and evidence-based humanitarian interventions. With nearly a decade of experience working with leading NGOs such as Impact Initiatives, Generations for Peace, BORDA WesCa, ARDD, and the WANA Institute, Hadeel brings deep expertise in academic, policy, and action research, as well as monitoring, evaluation, and needs assessments. <br/>Fluent in Arabic and English, Hadeel is an Alumna of Lazord and Local Pathways Fellowships, and is an active member of the Al-Sharq Youth Network. <br/>Known for her commitment to sustainable development, Hadeel leverages her skills to drive initiatives that foster resilience and address pressing social challenges`, 
    qualification: [
      "Master Degree of Architectural Engineering, University of Jordan",
      "Bachelor Degree in Architectural Engineering, University of Jordan",
      "Diploma of Training of Trainers, The Wheat Grain Academy",
      "Certified MEAL practitioner, PM4NGOs"
    ],
  },
  {
    id: "10",
    img: "hand_phone.jpg",
    firstName: "Hanif",

    lastName: "Osmani",
    linkedinUrl: "https://www.linkedin.com/in/hanifosmani",
    title: "Social Impact Specialist",
    description: `With over twenty years of experience in community development and social impact, Hanif has a unique perspective shaped by both professional expertise and lived experience. Growing up on the Ocean Estate in East London, UK - once described by the BBC as “the cheapest place in Europe to buy heroin,” he witnessed first-hand the challenges of poverty, crime, and social exclusion.<br/>Hanif has dedicated his career to transforming such realities, designing and managing impactful programmes in some of the UK's most deprived areas. Today, he specialises in digital engagement and innovation, helping social purpose organisations widen their reach and deepen their impact.<br/>From pioneering cross-sector partnerships to launching community-focused digital solutions, Hanif’s strategic and grassroots expertise continues to drive meaningful and sustainable change for communities in need.`, 
    qualification: [
      "Diploma of Higher Education (DipHE) in Computing and Business Information Technology, London Metropolitan University",
    ],
  },
];
export default partners;
