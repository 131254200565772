import React from 'react'
import BannerFour from '../elements/BannerFour';
import ServiceFour from '../elements/ServiceFour';
import Brand from '../elements/Brand';
import AboutFour from '../elements/AboutFour';
import ServiceFIve from '../elements/ServiceFIve';
import PortfolioFour from '../elements/PortfolioFour';
import Counter from '../elements/Counter';
import Contact from '../elements/Contact';
import BlogFour from '../elements/BlogFour';
import TestimonialsFour from '../elements/TestimonialsFour';
import Team from '../elements/Team';
import Footer from '../elements/Footer';
import Header from '../header/Header';
import WorkingProcess from '../elements/WorkingProcess';
import MNationAdvisoryProcess from '../elements/WorkingProcess';



function Home() {
  return (
    <div>
        <div className="index-Four">
            <Header/>
            <main>
                <BannerFour/>
                <MNationAdvisoryProcess/>
                {/* <ServiceFour/> */}
                <AboutFour/>
                
                 <Brand/>
                {/*<ServiceFIve/> */}
                {/* <PortfolioFour/>
                <Counter/> */}
                {/* <BlogFour/> */}
                {/* <TestimonialsFour/> */}
                {/* <Team/> */}
                <Footer/>
            </main>
        </div>
    </div>
  )
}

export default Home