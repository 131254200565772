import React from 'react'
import Breadcrumb from './breadcrumb/Breadcrumb';

import ServiceOne from '../elements/ServiceOne';
import WorkingProcessThree from '../elements/WorkingProcessThree';
import Team from '../elements/Team';
import TestimonialsBrand from '../elements/TestimonialsBrand';
import Footer from '../elements/Footer';
import Header from '../header/Header';





function Service() {
  return (

        <div className="">
            <Header/>
            <main>
               <Breadcrumb currentPage='Services' bgClass='breadcrumb-bg'  specialInnerClass='service-inner'/>
                <ServiceOne/>
                {/* <WorkingProcessThree/> */}
                {/* <Team/>
                <TestimonialsBrand/> */}
                <Footer/>

            </main>
        </div>
    )
}

export default Service