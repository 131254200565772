import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import Footer from "../elements/Footer";
import Header from "../header/Header";
import { useSearchParams } from "react-router-dom";
import partners from "../components/partners";
import Breadcrumb from "./breadcrumb/Breadcrumb";

function TeamDetailsOne() {
  const [partner, setPartner] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  useEffect(() => {
    const partnerId = searchParams.get("id");
    setPartner(partners.find(({ id }) => id === partnerId));
    console.log();
  }, []);

  return (
    <div>
      <Header />

      {partner && (
        <>
          <div>
            {/* Start Breadcrumb area  */}
            <Breadcrumb currentPage="Trusted Partner" bgClass="breadcrumb-bg" specialInnerClass="service-inner"/>
            {/* End Breadcrumb area  */}
          </div>
          <div className="team-details-area tmp-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="team-details-thumb">
                    <div className="thumbnail">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          paddingTop: "112.2%" /* 920/820*100 */,
                        }}
                      >
                        <img
                          src={`assets/images/team/${partner.img}`}
                          alt="Corporate team"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    <div className="content">
                      <div className="author-info">
                        <h4 className="name">
                          {partner.firstName} {partner.lastName}
                        </h4>
                        <span className="designation">{partner.title}</span>
                      </div>

                      <div className="team-details-social">
                        <ul className="social-icons solid-social-icons rounded-social-icons">
                      
                          <li>
                            <a
                              className="bg-transparent border-one-primary"
                              href={partner.linkedinUrl} target="_blank" rel="noreferrer"
                            >
                              <i className="fa-brands fa-linkedin-in" style={{color:"black", fontSize:30}}/>
                            </a>
                          </li>
                       
                          {/* <li>
                            <a
                              className="bg-transparent border-one-primary"
                              href="#"
                            >
                              <i className="fa-brands fa-instagram" />
                            </a>
                          </li> */}
                       
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="team-details-content">
                    <div className="personal-info">
                    <h3
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          position: "relative",
                        }}
                      >
                        Personal Info
                        <span
                          style={{
                            display: "block",
                            width: "50px",
                            height: "2px",
                            backgroundColor: "black",
                            marginTop: "4px",
                          }}
                        />
                      </h3>
                      {partner.description.split("<br/>").map((desc)=>{
                        if (String(desc).includes("<a")) {
                          return <p className="description" dangerouslySetInnerHTML={{
                            __html:desc
                          }}/>
                        }
                        return <p className="description">{desc}</p>
                      })}
                    </div>
                    <div style={{ maxWidth: "600px" }}>
                      <h3
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          position: "relative",
                        }}
                      >
                        Qualifications
                        <span
                          style={{
                            display: "block",
                            width: "50px",
                            height: "2px",
                            backgroundColor: "black",
                            marginTop: "4px",
                          }}
                        />
                      </h3>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "20px",
                          lineHeight: "1.6",
                        }}
                      >
                        {partner.qualification.map((qualification, index) => (
                          <li
                            key={index}
                            style={{ fontSize: "16px", marginBottom: "8px",color: "black" }}
                          >
                            <i class="fa fa-graduation-cap" aria-hidden="true" style={{marginRight: 10}}></i>
                            {qualification}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* End Team Details Area  */}

      <Footer />
    </div>
  );
}

export default TeamDetailsOne;
