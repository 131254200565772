import React from 'react';
import { Link } from 'react-router-dom';

const RenderService = ({ title, description, details }) => {
  return (
    <div
      className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft"
      data-wow-duration=".8s"
      data-wow-delay=".1s"
    >
      <div id="service-card" className="service-card">
        <div className="services-inner financial-service-inner agency-service">
          <div className="content">
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>
            <ul className="service-details">
              {details.map((detail, i) => (
                <li key={i}>
                  <i className="fas fa-check-circle list-icon"></i>
                  {detail}
                </li>
              ))}
            </ul>
            <Link to="/ServiceDetails" className="over_link"></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderService;
