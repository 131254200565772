const services = [
    {
      title: "Strategic Media Planning",
      description: "Development of customized media strategies for effective audience engagement.",
      details: [
        "Development of customized media strategies",
        "Identification of target audiences and key media outlets",
        "Messaging opportunities and alignment with business objectives"
      ]
    },
    {
      title: "Public Relations",
      description: "Building and maintaining a strong brand presence across media.",
      details: [
        "Crafting and distributing press releases",
        "Securing media coverage and arranging interviews",
        "Building and maintaining media relationships"
      ]
    },
    {
      title: "Media Training",
      description: "Preparing spokespeople for impactful media interactions.",
      details: [
        "Preparing spokespeople for media interactions",
        "Conducting mock interviews and practical exercises",
        "Enhancing communication skills and confidence"
      ]
    },
    {
      title: "Crisis Management",
      description: "Providing strategic counsel and communication support during crises.",
      details: [
        "Developing crisis management plans",
        "Providing strategic counsel during crises",
        "Crafting and delivering crisis communications"
      ]
    },
    {
      title: "Content Creation",
      description: "Creating content that aligns with brand voice and objectives.",
      details: [
        "Multi-modal content generation",
        "Aligning content with brand voice and objectives",
        "Production of press quality marketing collateral"
      ]
    },
    {
      title: "Policy Planning and Development",
      description: "Supporting policy-driven initiatives and compliance.",
      details: [
        "Formulating and implementing policy strategies",
        "Evaluating policy effectiveness and compliance",
        "Supporting policy-driven initiatives and advocacy"
      ]
    },
    {
      title: "Stakeholder Engagement",
      description: "Building and maintaining strong relationships with key stakeholders.",
      details: [
        "Identifying and engaging key stakeholders",
        "Facilitating stakeholder communications",
        "Building and maintaining stakeholder relationships"
      ]
    },
    {
      title: "Lead Generation and Sales Conversion Mentoring",
      description: "Guiding clients on market research, lead scoring, and conversion.",
      details: [
        "Market research and analysis",
        "Strategic networking for client connections",
        "Implementing lead scoring systems"
      ]
    },
    {
      title: "Product and Services Development",
      description: "Helping clients streamline product development and overcome technical challenges.",
      details: [
        "Process Optimization: Streamlining product development from ideation to launch",
        "Technical Expertise: Overcoming tech challenges for market leadership"
      ]
    },
    {
      title: "Reputation Management",
      description: "Monitoring and protecting your brand’s public image.",
      details: [
        "Monitoring and managing brand reputation",
        "Risk mitigation against negative publicity",
        "Implementing long-term reputational strategies"
      ]
    },
    {
      title: "Internal Communications",
      description: "Improving internal engagement and aligning messaging with organizational goals.",
      details: [
        "Developing internal communication plans",
        "Enhancing employee engagement and information flow",
        "Aligning internal messaging with organizational objectives"
      ]
    }
  ];
  export default services