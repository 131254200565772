import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import partners from "../components/partners";

function renderPartner({ firstName, lastName, title, img, id, linkedinUrl }) {
  return (
    <div
      className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft"
      data-wow-duration=".8s"
      data-wow-delay=".1s"
    >
      <div
        className="single-team"
        style={{ maxWidth: "300px", width: "100%", margin: "auto" }}
      >
        <Link to={`/teamDetails?id=${id}`} className="thumbnail">
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingTop: "112.2%", // Aspect ratio
              overflow: "hidden",
            }}
          >
            <img
              src={`assets/images/team/folder_marble.jpg`}
              alt={`${firstName} ${lastName}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              className="designation"
              style={{
                position: "absolute",
                bottom: "50%",
                left: "50%",
                transform: "translateX(-50%)",
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                padding: "6px 12px",
                borderRadius: "4px",
                fontSize: 14,
                fontWeight: "bold",
                textAlign: "center",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </div>
          </div>
        </Link>
        <div className="content">
          <div
            className="team-name content-with-bg"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          >
            <Link to={`/teamDetails?id=${id}`} className="name-area">
              <h6
                className="name"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: 18,
                }}
              >
                {`${firstName} ${lastName}`}
              </h6>
            </Link>
          </div>
          <div className="share-icon-style-one">
            <ul className="social-icons social-icons-with-bg">
              <li>
                <Link to={linkedinUrl} target="_blank">
                  <i className="fa-brands fa-linkedin-in" />
                </Link>
              </li>
            </ul>
            <i className="fa-sharp fa-solid fa-share-nodes" />
          </div>
        </div>
      </div>
    </div>
  );
}
function Team() {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  return (
    <div>
      <>
        {/* Start team area  */}
        <div className="team-area tmp-section-gap bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-head">
                  <h2 className="section-title split-collab">
                    TRUSTED ADVISORY PARTNERS
                  </h2>
                </div>
              </div>
            </div>
            <div className="row g-5">{partners.map(renderPartner)}</div>
          </div>
        </div>
        {/* End team area  */}
      </>
    </div>
  );
}

export default Team;
