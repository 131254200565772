import React from 'react'
import Team from '../elements/Team';
import Footer from '../elements/Footer';
import AboutSix from '../elements/AboutSix';
import Header from '../header/Header';
import Breadcrumb from './breadcrumb/Breadcrumb';


function about() {
    return(

        <div className="index-one">

            <Header/>   
        <main>
            <Breadcrumb currentPage='About Us' bgClass='about-bg'/>
            {/* <AboutOne/>
            <AboutTwo/>
            <AboutThree/>
            <AboutFour/>
            <AboutFive/> */}
            <AboutSix/>
            <Team/>
            {/* <TestimonialsBrand/> */}
            <Footer/>


        </main>


        </div>
        
    )
}

export default about