import React from 'react'
import RxButton from '../components/RxButton'

function Brand() {
  return (
    <div>
        {/* trusted client */}
        <div className="tmp-section-gapBottom tmp-trusted-clients">
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <div className="trusted-clients-agency-wrapper">
                  
                  <RxButton Text={"Contact us"}/>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* trusted clients end */}
    </div>
  )
}

export default Brand