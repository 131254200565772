import React, { useEffect } from "react";
import WOW from "wowjs";
const clientLevels = [
  {
    index: 1,
    title: "Groundbreaker",
    description:
      "Just starting out and establishing a foothold. You need guidance on brand identity and strategies to connect with your target audience.",
  },
  {
    index: 2,
    title: "Visibility Pioneer",
    description:
      "You've gained some traction but struggle with public recognition. Your need is to enhance visibility and build strong relationships with media outlets.",
  },
  {
    index: 3,
    title: "Growth Architect",
    description:
      "As your influence expands, managing reputation and communication becomes critical. You require a robust framework to navigate market challenges and maintain momentum.",
  },
  {
    index: 4,
    title: "Legacy Builder",
    description:
      "A leader in your industry, you aim to solidify your legacy. Your need is for advanced strategies that ensure sustainability and foster enduring stakeholder relationships.",
  },
];

function renderProcess({ title, index, description }) {
  return (
    <div
      className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInLeft"
      data-wow-duration=".8s"
      data-wow-delay={`${index * 0.1}s`} // Delay based on index
    >
      <div className="single-process">
        <div className="services-inner">
          <div className="services-content services-content-style-1 text-center">
            <h5 className="title" style={{ textAlign: "left" }}>
              {title}
            </h5>
            <p className="description" style={{ textAlign: "left" }}>
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
function WorkingProcess() {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  return (
    <div>
      <div className="tpm-services-process-area tmp-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
                <div className="section-sub-title center-title"></div>
                <h2 className="title split-collab">
                  MNATION Advisory is by your side, for every step along the
                  way.
                </h2>
              </div>
            
          </div>
          <div className="row g-5">{clientLevels.map(renderProcess)}</div>
        </div>
      </div>
    </div>
  );
}

export default WorkingProcess;
